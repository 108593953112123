<div class="product-preview-container" *ngIf="product">
  <app-product-gallery
    (downloadedImages)="onImagesDownloaded()"
    (downloadedVideos)="onVideosDownloaded()"
    (reviewProduct)="onReviewProduct()"
    (customizeLanding)="onCustomizeLanding()"
    (productCheckoutDetailEmit)="onProductCheckoutRead($event)"
    [productGallery]="productGallery"
    [productUrl]="productUrl"
    [basicInfo]="basicInfo"
    [hasMultipleVariants]="hasMultipleVariants"
  >
  </app-product-gallery>

  <div class="left-column">
    <app-product-basic-info
      [basicInfo]="basicInfo"
      [isPriceRangeEnabled]="isPriceRangeEnabled"
      [currentProduct]="currentProduct"
      [saleInfo]="saleInfo"
      [flashSaleTitle]="flashSaleTitle"
      [productAvailability]="productAvailability"
      [productIsOrderable]="productIsOrderable"
      [userHasStore]="userHasStore"
      [storeProvider]="storeProvider"
      [productAddedToStore]="productAddedToStore"
      [userHasStockAvailability]="userHasStockAvailability"
      [showStockAvailability]="showStockAvailability"
      [stockAvailabilityStatus]="stockAvailabilityStatus"
      [stockAvailabilityRunRate]="stockAvailabilityRunRate"
      [isDiscountAsSecondProduct]="isDiscountAsSecondProduct"
      [isDiscountOnQuantityEnabled]="isDiscountOnQuantityEnabled"
      [skuAnalyticsEnabled]="skuAnalyticsEnabled"
      [skuAnalytics]="skuAnalytics"
      [variantsData]="variantsData"
      [isMarketplaceEnabled]="isMarketplaceEnabled"
      (addProductToStore)="onAddProductToStore()"
      (addToCart)="onAddToCart()"
      (orderNow)="onOrderNow($event)"
      (variantSelected)="onSelectVariant($event)"
    >
    </app-product-basic-info>

    <app-product-actions
      [basicInfo]="basicInfo"
      [isDiscountOnQuantityEnabled]="isDiscountOnQuantityEnabled"
      [productAvailability]="productAvailability"
      [productIsCataloged]="productIsCataloged"
      [bulkPreOrderButtonIsVisible]="bulkPreOrderButtonIsVisible"
      [productIsOrderable]="productIsOrderable"
      [userHasStockAvailability]="userHasStockAvailability"
      [isBundle]="product?.type === 'bundle'"
      [isMarketplaceEnabled]="isMarketplaceEnabled"
      (addToCart)="onAddToCart()"
      (openBulkRequestDialog)="onOpenBulkRequestDialog()"
      (orderNow)="onOrderNow($event)"
      (toggleIsCataloged)="onToggleIsCataloged()"
    >
    </app-product-actions>
  </div>
</div>
<div class="product-additional-info-container">
  <app-product-additional-info
    *ngIf="
      product.productDescription ||
      product.specifications ||
      product.howToUse ||
      product.embeddedVideos.length > 0
    "
    [additionalInfo]="additionalInfo"
    [prodId]="product.prodID"
    [productCheckoutDetail]="productCheckoutDetail"
    (changeDisplayedAdditionalInfo)="onDisplayedAdditionalInfoChange($event)"
    (checkoutProductPixelID)="onCheckoutProductPixelID($event)"
    (checkoutProductFormData)="onCheckoutProductForm($event)"
  >
  </app-product-additional-info>
</div>
